import { useState, useMemo, memo, FC } from 'react'
import { cn, formatGameImg } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'
import { usePathname } from 'next/navigation'
import { CSSTransition } from 'react-transition-group'
// --- 待处理
import { openGame, favoriteGameFun } from '@/components/game/startGame'
// import AnimationNum from "@/components/base/AnimationNum"
import { numThFilter, formatAmount } from '@/lib/filter'
import styles from './_common_css/gameItemMobile.module.scss'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  gameData?: {
    property: string
    gameName: string
    amigoGameType: string
    gameType: string
    gameProperties: any
    gameId: string
    platformCode: string
    smallMediaLibrary: any
    rtp: any
    totalWinAmount: any
    totalBetAmount: any
    lobbyCode: string
    providerName: string
    trialStatus?: any
  }
  isSearchResult?: boolean
  type?: string
  gameIndex?: any
  isSameHeight?: any
  colorList?: any
}

const GameItemMobile: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { isBigScreen, commonConfig } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      commonConfig: state.commonConfig
    }))
  )
  const { gameSearchDialogShow, favoriteGameList } = useGameStore(
    useShallow((state) => ({
      gameSearchDialogShow: state.gameSearchDialogShow,
      favoriteGameList: state.favoriteGameList
    }))
  )
  const isLogin = useUserStore((state) => state.isLogin)
  const pathName = usePathname()

  //  待处理
  // const { proxy } = getCurrentInstance()
  // const attrs = useAttrs()
  const [showDetail, setShowDetail] = useState(false) // 是否查看详情

  // 收藏事件 待处理
  const favoriteFun = (item) => {
    favoriteGameFun(item)
  }

  const isAmigoSlot = useMemo(() => {
    return (
      props.gameData.gameType === 'pachislot' ||
      props.gameData.amigoGameType === 'slot' ||
      props.gameData.lobbyCode === 'KAMIKAZE'
    )
  }, [])

  const isPachinko = useMemo(() => {
    return props.gameData.gameType === 'pachinko' || isAmigoSlot
  }, [])

  const gameProperty = useMemo(() => {
    if (isPachinko) {
      return props.gameData.property || props.gameData.gameProperties?.gameProperty
    } else {
      return null
    }
  }, [])

  // 待处理
  const onGoToGame = (isTry, params) => {
    if (gameSearchDialogShow || pathName === '/account/multimode') {
      openGame(isTry, params)
    } else {
      openGame(isTry, params)
    }
  }

  return (
    <>
      <div className={styles.root}>
        <div
          className={cn(
            `game-type-${props.gameData.gameType}`,
            isBigScreen ? 'pc' : 'h5',
            // !isPachinko(props.gameData) 原项目判断 待观察
            !isPachinko && 'no-pachinko',
            props.isSearchResult && 'search-result',
            'game_item'
          )}
          onClick={() => onGoToGame(0, props.gameData)} // 待处理
        >
          {props.gameData && (
            <div
              className={'game-img'}
              // v-lazy: 待观察
              // background-image={() => formatGameImg(props.gameData)}
              style={{
                backgroundImage: `url(${formatGameImg(JSON.stringify(props.gameData), commonConfig)}`
              }}
            ></div>
          )}

          {isLogin && (
            <div
              className={cn(
                // !isPachinko(props.gameData) && 'no-pachinko', // 原项目 待观察
                !isPachinko && 'no-pachinko',
                favoriteGameList.some(
                  (e) => e.gameId === props.gameData.gameId && e.providerCode === props.gameData.platformCode
                ) && 'favorited',
                'fav-icon'
              )}
              onClick={(event) => {
                event.stopPropagation()
                favoriteFun(props.gameData) // 待处理
              }}
            ></div>
          )}

          {/* H5点击查询详情 */}
          <div
            className={'detail-icon'}
            onClick={(event) => {
              event.stopPropagation()
              setShowDetail(true)
            }}
          ></div>

          {/* 数据来源：/cms/open/gameLabels/gameLabelsList, 搜索模块数据来源：/cms/v1/element/material/game_page */}
          <div className={'game-name-wrap'}>
            <div className={'game-name-wrap-txts'}>
              <p className={cn('game-name', 'text-multi-ellipsis-l2')}>{props.gameData.gameName}</p>
              <span>{props.gameData.providerName}</span>
            </div>
          </div>
          <div className={'after'}></div>

          {/* H5黑色蒙层详情 */}
          <CSSTransition timeout={200} in={showDetail} classNames={'fade'}>
            <div className={'mask-detail'}>
              <h3 className={cn((isAmigoSlot || isPachinko) && 'one-line', 'text-multi-ellipsis-l2')}>
                {props.gameData.gameName}
              </h3>
              {props.gameData.totalBetAmount &&
              props.gameData.totalBetAmount != 0 &&
              props.type !== 'pachinkoWin' ? (
                <>
                  <span>{t('common.game.dailyBet')}</span>
                  <strong>${numThFilter(props.gameData.totalBetAmount || 0, 0)}</strong>
                </>
              ) : (
                <>
                  {' '}
                  {props.gameData.totalWinAmount &&
                    props.gameData.totalWinAmount != 0 &&
                    props.type === 'pachinkoWin' && (
                      <>
                        <span>{t('common.game.winBet')}</span>
                        <strong>${numThFilter(props.gameData.totalWinAmount || 0, 0)}</strong>
                      </>
                    )}
                </>
              )}

              {gameProperty && isAmigoSlot && (
                <>
                  {/* 测试需求，lobbyCode为KAMIKAZE时不显示ceiling */}
                  {props.gameData.lobbyCode !== 'KAMIKAZE' && (
                    <>
                      <div>
                        {t('common.game.ceiling')}
                        <span className={'num'}>{gameProperty.ceiling || '-'}</span>
                      </div>
                      <div>
                        {t('common.game.token')}
                        <span className={'num'}>${gameProperty.onePiece || '-'}</span>
                      </div>
                      <div>
                        {t('common.game.type')}
                        <span className={'num'}>{gameProperty.type || '-'}</span>
                      </div>
                    </>
                  )}
                </>
              )}
              {gameProperty && isPachinko && (
                <>
                  {props.gameData.lobbyCode !== 'KAMIKAZE' && (
                    <>
                      <div>
                        {t('common.game.probability')}
                        <span className={'num'}>{gameProperty.probability || '-'}</span>
                      </div>
                      <div>
                        {t('common.game.ball')}
                        <span className={'num'}>${formatAmount(gameProperty.oneBead, 3)}</span>
                      </div>
                      <div>
                        {t('common.game.roll')}
                        <span className={'num'}> {formatAmount(gameProperty.oneTurn, 3)}</span>
                      </div>
                    </>
                  )}
                </>
              )}
              {gameProperty && (
                <>
                  <p>RTP {(props.gameData.rtp * 100).toFixed(2)}%</p>
                  <p>
                    {t('common.game.probability')}
                    <span className={'num'}>{gameProperty.ceiling || '-'}</span>
                  </p>
                  <p>
                    {t('common.game.ball')}
                    <span className={'num'}>${formatAmount(gameProperty.oneBead, 3)}</span>
                  </p>
                  <p>
                    {t('common.game.type')}
                    <span className={'num'}> {formatAmount(gameProperty.oneTurn, 3)}</span>
                  </p>
                </>
              )}

              <div className={'bottom-box'}>
                <button onClick={() => onGoToGame(0, props.gameData)}>
                  {isLogin ? t('common.game.play') : t('common.game.goPlay')}
                </button>
                {props.gameData.trialStatus && !isLogin && (
                  <button onClick={() => onGoToGame(1, props.gameData)}>{t('common.game.funPlay')}</button>
                )}
              </div>
              <img
                onClick={(event) => {
                  event.stopPropagation()
                  setShowDetail(false)
                }}
                className={'close-icon'}
                src="/static/images/game/close.svg"
              />
            </div>
          </CSSTransition>

          {props.gameData.smallMediaLibrary && (
            <img
              src={
                props.gameData.smallMediaLibrary?.fullWebpFilePath ||
                props.gameData.smallMediaLibrary?.fullFilePath
              }
              className={'top_icon'}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default memo(GameItemMobile)
