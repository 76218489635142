import { useEffect, FC, memo } from 'react'
import { useDebounceFn } from 'ahooks'
import { useTranslation } from '@/lib/locales/client'
// import { useAppStore } from '@/stores/app'
import styles from './_common_css/gameSeachPageResult.module.scss'
import GameItemMobile from './gameItemMobile'
import { useGameStore } from '@/stores/game'
import { useGameData } from '../../app/[lng]/(game)/_components/useGameData'
import Nodata from '@/components/base/noData'

type IProps = {
  searchGameName: string
  searchProviderList: any[]
}

const GameSeachPageResult: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const allGameLen = useGameStore((state) => state.allGameLen)
  const { filterGameList, totalCount, useSearchType, getGameData, setFilterGameList, setTotalCount } =
    useGameData()
  const seachFn = () => {
    if (props.searchGameName === '' && props.searchProviderList.length === 0) {
      setFilterGameList([])
      setTotalCount(0)
      return
    }
    delete useSearchType.value.gameIds
    delete useSearchType.value.gameTypes
    useSearchType.value.comboName = props.searchGameName
    useSearchType.value.providerCodes = props.searchProviderList
    useSearchType.value.pageNo = 1

    getGameData()
    document.querySelector('.seach_content').scrollTo(0, 0)
  }

  const { run } = useDebounceFn(seachFn, { wait: 300 })

  const loadMore = () => {
    useSearchType.value.pageNo = useSearchType.value.pageNo + 1
    getGameData()
  }

  // 待处理 search可能 是个list
  // useEffect(() => {
  //   run()
  // }, [props.searchGameName, props.searchProviderList])

  return (
    <>
      <div className={styles['game_page_seach_warp']}>
        {filterGameList.length > 0 && (
          <p
            className={'game_page_seach_title'}
            dangerouslySetInnerHTML={{
              __html: t('home.game.resultHtml', { target: totalCount, total: allGameLen })
            }}
          ></p>
        )}
        <section className={'game_page_seach'}>
          <div className={'seach-game-warp'}>
            {filterGameList.map((item) => (
              <GameItemMobile
                isSearchResult
                key={item.gameId + item.gameType}
                gameData={item}
              ></GameItemMobile>
            ))}
            {filterGameList.length === 0 && props.searchGameName.length > 0 && <Nodata />}
          </div>
          {filterGameList.length < totalCount && (
            <div className={'load-more'}>
              <div className={'bar'}>
                <span>{Number((filterGameList.length / totalCount) * 100)}%</span>
                <div className={'bar-warp'}>
                  <div style={{ width: `${Number((filterGameList.length / totalCount) * 100)}'%'` }}></div>
                </div>
                <p>
                  {filterGameList.length} / {totalCount}
                </p>
              </div>
              <a className={'load-btn'} onClick={loadMore}>
                {t('home.game.loadmore')}
              </a>
            </div>
          )}
        </section>
      </div>
    </>
  )
}

export default memo(GameSeachPageResult)
