'use client'
import { FC, useEffect, useMemo } from 'react'
import styles from '@/components/account/_common_css/verify/VerifyBaseDialog.module.scss'
import { cn } from '@/lib/utils'
import { Modal } from 'antd'

type IProps = {
  width?: string | number
  visible?: boolean
  cusClass?: string
  isFullScreen?: boolean
  needCenter?: boolean
  zIndex?: number // 5000
  type?: string // common
  closeFn?: (flag: boolean) => void
  updateFullScreenClass?: (val?: string) => void
  [key: string]: any
}

const VerifyBaseDialog: FC<IProps> = ({ children, ...props }) => {
  // emits: ["closed", "closeFn"],
  // const [visibleFlag, setVisibleFlag] = useState(false)
  // const {t} = useTranslation()
  // useEffect(() => {
  //   setVisibleFlag(props.visible)
  // }, [props.visible])

  // const visibleFlag = useMemo( () => {
  //   set(value: boolean) {
  //     return value
  //   }
  //   ), [props.visible]}
  // props.isFullScreen ? "full-modal" : "not-full"
  const mapImages = useMemo(
    () => [
      {
        key: 'common',
        image: '/static/images/myAccount/verification/model_middle_icon.png'
      },
      {
        key: 'alert',
        image: '/static/images/myAccount/verification/model_middle_icon_alert.png'
      },
      {
        key: 'info',
        image: '/static/images/myAccount/verification/model_middle_icon_info.png'
      },
      {
        key: 'checkin',
        image: '/static/images/myAccount/verification/model_middle_icon_checkin.png'
      },
      {
        key: 'bonus',
        image: '/static/images/myAccount/verification/model_middle_icon_bonus.png'
      },
      {
        key: 'none',
        image: '/static/images/myAccount/verification/model_middle_register_success.png'
      }
    ],
    []
  )

  useEffect(() => {
    if (!props.visible) {
      // emit("closed")  // 待处理
      props.closeFn(false)
    }
  }, [props.visible])

  return (
    <>
      <Modal
        className={cn(styles['base-dialog'], props.cusClass)}
        closable={false}
        width={props.width}
        footer={null}
        open={props.visible}
        styles={{
          body: {
            padding: 0
          }
        }}
        // cancel={$emit('handle-close')} // 待处理
        wrapClassName={props.isFullScreen ? styles['full-modal'] : styles['not-full']}
        centered={props.needCenter}
        z-index={props.zIndex}
      >
        <div className={styles['verify_model']}>
          <div className={'top_model'}>
            <img
              onClick={() => props.closeFn(false)} // --- 待处理
              className={'delete_x'}
              src="/static/images/myAccount/verification/delete_x.png"
              alt=""
            />
            <img
              className={'left_arrow'}
              src="/static/images/myAccount/verification/model_left_arrow.png"
              alt=""
            />
            <div className={'middle_div'}>
              {mapImages.map((item, index) => (
                <div key={index}>{item.key === props.type && <img src={item.image} alt="" />}</div>
              ))}
            </div>
          </div>
          <div className={"content_main"}>{children}</div>
        </div>
      </Modal>
    </>
  )
}

export default VerifyBaseDialog
