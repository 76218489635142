// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import gameTips from "./transferDialog.ts"

let messageInstance
function getMessageInstance() {
  messageInstance = messageInstance || gameTips.newInstance()
  return messageInstance
}

export default {
  open(callBack) {
    return getMessageInstance().open(callBack)
  },
  close() {
    return getMessageInstance().close()
  }
}
