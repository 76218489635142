import { useRef, FC, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useRouter, usePathname } from 'next/navigation'
import { Autoplay, Navigation, Grid } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import GameItem from '@/components/game/gameItem'
import GameItemMobile from '@/components/game/gameItemMobile'
import { randomGenKey } from '@/hooks/encrypt'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/grid'
import styles from './_common_css/homeGameListMobile.module.scss'

type IProps = {
  gameListData: any
  // gameListData: {
  //   title?: string
  //   detailsList?: any
  //   colorList?: any
  // }
  isSameHeight?: Boolean // false
  grid?: {
    rows: number // 1
    // fill: string  //"row"
  }
  isHideTitle?: Boolean // false
  href?: string
  type?: string
  key?: any
  className?: string
}

// , {grid: {rows: 1, fill: "row"}}
const HomeGameListMobile: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const router = useRouter()
  const pathName = usePathname()
  const gameListRef = useRef(null)
  // const instance = getCurrentInstance() // + instance?.uid
  const randomKey = useMemo(() => 'key' + randomGenKey(4), [])

  // console.log('读取数据', props)
  // const componentKey = ref(random1000)
  // useEffect(() => {
  //   if( isLogin ) setStep(1)
  // }, [])

  return (
    <>
      <div className={cn(styles['casino-hidde_warp'], props.className)}>
        <section ref={gameListRef} className={cn(isBigScreen ? 'pc' : 'h5', randomKey, 'home_game_list')}>
          {!props.isHideTitle && (
            <h1>
              {props.gameListData.title}
              {/* --- 待处理  props.href || */}
              <a onClick={() => router.push('/')}>{t('common.homeIcon.more')}</a>
            </h1>
          )}
          {isBigScreen ? (
            <Swiper
              className={'home-game-mobile-swiper'}
              modules={[Autoplay, Navigation, Grid]}
              slidesPerView={6}
              slidesPerGroup={6}
              spaceBetween={16}
              grid={props.grid}
              grabCursor
              navigation={{
                nextEl: `.home_game_list.${randomKey} .swiper-next`,
                prevEl: `.home_game_list.${randomKey} .swiper-prev`
              }}
            >
              {props.gameListData &&
                props.gameListData.detailsList.map((item, index) => (
                  <SwiperSlide key={index}>
                    <GameItem
                      isSameHeight={props.isSameHeight}
                      gameData={item}
                      gameIndex={index}
                      colorList={props.gameListData.colorList}
                    ></GameItem>
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <Swiper
              className={'swiper'}
              modules={[Autoplay, Navigation, Grid]}
              slidesPerView={2.57}
              spaceBetween={6}
              grid={props.grid}
              grabCursor
            >
              {props.gameListData &&
                props.gameListData.detailsList.map((item, index) => (
                  <SwiperSlide key={index}>
                    <GameItemMobile
                      {...props}
                      gameData={item}
                      gameIndex={index}
                      isSameHeight={props.isSameHeight}
                      colorList={props.gameListData.colorList}
                      //  v-bind="$attrs"  // 待处理
                      type={props.type}
                    ></GameItemMobile>
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
        </section>
      </div>
    </>
  )
}

export default memo(HomeGameListMobile)
