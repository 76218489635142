// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// import { createApp } from "vue"  // ---  待处理
import tipsDialog from './tipsDialog'

tipsDialog.newInstance = (properties) => {
  const props = properties || {}
  const container = document.createElement('div')
  const instance = createApp(tipsDialog, props)
  const component = instance.mount(container)
  document.body.appendChild(container)

  return {
    open(callBack) {
      component.open(callBack)
    },
    close() {
      component.close()
    }
  }
}
export default tipsDialog
