import { useState, useEffect, useMemo } from 'react'
import { styled } from 'styled-components'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { formatAmount } from '@/lib/filter'
import { getExchangeRate } from '@/api/wallet'
import VerifyBaseDialog from '@/components/account/verify/components/VerifyBaseDialog'
import { getCurrencySymbol } from '@/lib/utils'

const TipsDialog = () => {
  const { t } = useTranslation()
  const { tipsDialogFlag, setTipsDialogFlag } = useAppStore()
  const userStore = useUserStore()
  const [rate, setRate] = useState(1)
  const [confirmFun, setConfirmFun] = useState(null)
  const [loading, setLoading] = useState(false)

  const currencyData = useMemo(() => {
    const isBonusCoupon = userStore.moneyTypeSelectIndex === 1 && userStore.bonusCouponItem
    return isBonusCoupon ? userStore.bonusCouponItem : userStore.currency
  }, [])

  const changeAmount = useMemo(() => {
    return formatAmount(currencyData.amount * rate) || '0.00'
  }, [])

  useEffect(() => {
    if (tipsDialogFlag) {
      queryExchangeRate()
    }
  }, [tipsDialogFlag])

  const open = (callBack) => {
    setTipsDialogFlag(true)
    if (typeof callBack === 'function') {
      setConfirmFun(callBack)
    }
  }

  const close = () => {
    setTipsDialogFlag(false)
  }

  const queryExchangeRate = () => {
    const params = {
      amount: 1,
      isFindByCache: 0,
      isSave: false,
      targetCurrency: userStore.gameCurrency,
      sourceAmount: 1,
      sourceCurrency: currencyData.currency,
      tradeType: '02',
      used: 1
    }
    setLoading(true)
    getExchangeRate(params).then((res: any) => {
      setLoading(false)
      if (res.success && res.data.data && res.data.data.length > 0) {
        setRate(res.data.data[0].realRate)
      }
    })
  }

  const handleConfirm = () => {
    close()
    confirmFun()
  }

  return (
    <>
      <StyledTipsDialog
        visible={tipsDialogFlag}
        zIndex={6100}
        width={343}
        closeFn={close}
        cusClass="account-transfer"
      >
        <div className="container">
          <p className="title">{t('home.game.tips1')}</p>
          <p className="text">
            {t('home.game.tips2')}
            <span className="red">
              {getCurrencySymbol(currencyData.currency)}
              {formatAmount(currencyData.amount)}
            </span>

            {currencyData.currency !== 'JPY' && currencyData.currency !== 'USD' && (
              <span className="red">{currencyData.currency}</span>
            )}

            {t('home.game.tips3')}
            <span className="red">
              {getCurrencySymbol(userStore.gameCurrency)}
              {loading ? '--' : changeAmount}
            </span>
            {t('home.game.tips4')}
          </p>
          <ul>
            <li>{t('home.game.tips6')}</li>
            <li>{t('home.game.tips7')}</li>
          </ul>
          <div className="tip-wrap">{t('home.game.tips7')}</div>

          <div className="btn" onClick={handleConfirm}>
            {t('home.game.tips8')}
          </div>
        </div>
      </StyledTipsDialog>
    </>
  )
}

export default TipsDialog

const StyledTipsDialog = styled(VerifyBaseDialog)`
  .account-transfer {
    .container {
      position: relative;
      padding: 16px;
      border-radius: 4px;
      background: #fff;

      > img {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 24px;
        cursor: pointer;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #000000d8;
        margin-bottom: 24px;
      }

      .text {
        color: #000000a5;
        font-size: 16px;
        margin-bottom: 12px;
        line-height: normal;
        line-height: 24px; /* 150% */
        text-align: center;
      }

      .red {
        color: #fc005b;
        font-weight: 500;
      }

      .btn {
        width: 100%;
        height: 48px;
        text-align: center;
        line-height: 48px;
        border-radius: 5px;
        background: #fc005b;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        margin-top: 16px;
        cursor: pointer;
      }

      > ul {
        color: #000000a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        list-style: inside;
        li + li {
          margin-top: 8px;
        }
        > li {
          font-size: 16px;
          line-height: 24px; /* 150% */
        }
      }
    }
    .tip-wrap {
      border-radius: 4px;
      background: rgba(252, 0, 91, 0.1);
      padding: 16px;
      margin-top: 24px;
      :deep {
        p {
          color: #48001a;
          font-size: 16px;
          line-height: 24px; /* 150% */
        }
        p + p {
          margin-top: 8px;
        }
      }
    }
  }

  @media screen and (max-width: 999px) {
    .account-transfer {
      .container {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 16px;
          margin-bottom: 16px;
        }
        .text {
          font-size: 14px;
          margin-bottom: 8px;
        }
        ul {
          li {
            font-size: 14px;
            line-height: 22px; /* 157.143% */
          }
        }
        .tip-wrap {
          margin-top: 16px;
          padding: 8px 16px;
          flex: 1;
          overflow-y: auto;
        }
      }
    }
  }
`
