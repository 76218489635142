import { useState, useEffect, useMemo, useRef, FC, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { CSSTransition } from 'react-transition-group'
import styles from './_common_css/slotsGameProvider.module.scss'
import { Checkbox } from 'antd'
import { getGameProvider } from '@/api/game'
import { useClickAway } from 'ahooks'
import { useGameStore } from '@/stores/game'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  gameType?: string
  finish: any
  className?: string
}

const SlotsGameProvider: FC<IProps> = (props) => {
  const { gameType = '' } = props // 解构用于判断 默认字符串状态
  const { t } = useTranslation()
  const language = useAppStore((state) => state.language)
  const { allGameLen, SET_GAME_LEN } = useGameStore(
    useShallow((state) => ({
      allGameLen: state.allGameLen,
      SET_GAME_LEN: state.SET_GAME_LEN
    }))
  )

  let allValue = []
  const initText = useMemo(() => (gameType === '' ? t('home.game.provider') : t('home.game.select')), [])
  const [tabText, setTabText] = useState(initText)
  const [plainOptions, setPlainOptions] = useState([])
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [btnState, setBtnState] = useSetState({
    checkAll: false,
    checkedList: []
  })
  const menuRef = useRef<any>(null)
  // 关联弹出选择框
  useClickAway(() => {
    // console.log('点外部', isShowOptions)
    if (isShowOptions) {
      setIsShowOptions(false)
      // 待观察
      props.finish({ checkedList: btnState.checkedList })
      // emits("finish", {
      //   checkedList: btnState.checkedList
      // })
    }
  }, menuRef)

  useEffect(() => {
    setBtnState({ checkAll: btnState.checkedList.length === plainOptions.length })
    setTabText(btnState.checkedList.length > 0 ? btnState.checkedList.toString() : initText)

    // 待观察 原项目放外面
    queryGameLobbyCode()
  }, [])
  // --- 待处理 原项目 依赖 [btnState.checkedList]

  function handleDelete() {
    setBtnState({ checkAll: false, checkedList: [] })
  }

  function onCheckAllChange(e) {
    Object.assign(btnState, {
      checkedList: e.target.checked ? allValue : []
    })
  }

  function queryGameLobbyCode() {
    let gameTypes = gameType === '' ? {} : { gameTypes: [gameType] }
    return getGameProvider({
      language: language,
      ...gameTypes,
      visibleType: 1
    }).then((res: any) => {
      if (res.success) {
        setPlainOptions(res.data)
        let len = 0
        res.data.forEach((el) => {
          allValue.push(el.providerName)
          len += el.quantity
        })
        if (gameType === '') {
          SET_GAME_LEN(len)
        }
      }
    })
  }

  function handleSearch() {
    if (btnState.checkedList.length === 0) return
    setIsShowOptions(false)
    // 待观察
    props.finish({ checkedList: btnState.checkedList })
    // emits("finish", {
    //   checkedList: btnState.checkedList
    // })
  }

  return (
    <>
      <div
        className={cn(styles[(gameType === '' ? 'home' : 'slot') + '-provider'], props.className)}
        ref={menuRef}
      >
        <div
          className={cn(
            'header-tab',
            isShowOptions && 'open',
            btnState.checkedList.length === 0 && 'nochose'
          )}
          onClick={() => setIsShowOptions(!isShowOptions)}
        >
          {tabText}
        </div>
        <CSSTransition timeout={200} in={isShowOptions} unmountOnExit classNames={'fade'}>
          <div className={'list-box'}>
            <div className={'list-item'}>
              <Checkbox
                className={'checkbox-list-item-top'}
                checked={btnState.checkAll}
                onChange={onCheckAllChange}
              >
                <span>{t('common.account.all') + ' - ' + allGameLen}</span>
                <div className={'delete-icon'}>
                  <span
                    className={'cancel'}
                    onClick={(event) => {
                      event.preventDefault()
                      handleDelete()
                    }}
                  >
                    {t('home.game.cancel')}
                  </span>
                  <span
                    className={cn('search', btnState.checkedList.length === 0 && 'disable')}
                    onClick={(event) => {
                      event.preventDefault()
                      handleSearch()
                    }}
                  >
                    {t('home.game.search')}
                  </span>
                </div>
              </Checkbox>
              <Checkbox.Group value={btnState.checkedList}>
                {plainOptions.map((item) => (
                  <Checkbox key={item.providerName} value={item.providerName}>
                    <span>{item.providerName}</span>
                    <span className={'right-text'}>{item.quantity || 0}</span>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          </div>
        </CSSTransition>
      </div>
    </>
  )
}

export default memo(SlotsGameProvider)
