import { post, get } from './config/config'
import Config from '@/config/base'

export const getCode = () => post('/sys/auth/verifyCode')

// 查询网站游戏标签
export const cmsTopList2 = (params: any) => {
  params.themeCode = Config.themeCode
  return post('/api/phoenix/cms/open/cmsTopList/list2', params, { hiddeErr: true, hideLoading: true })
  // return post('/api/phoenix/cms/open/cmsTopList/list2', params, { hiddeErr: true, hideLoading: true }) test loading
}
// 查询网站游戏标签
export const cmsGameLabels = (params: any) => {
  params.themeCode = Config.themeCode
  return post('/api/phoenix/cms/open/gameLabels/gameLabelsList', params, {
    hiddeErr: true,
    hideLoading: true
  })
}
// 查询 榜单
export const cmsTopList = (params: any) => {
  params.themeCode = 'ANIMEFRESH'
  params.categoryCode = 'HomeConfigTemplate'
  return post('/api/phoenix/cms/open/cmsTopList/list', params, { hiddeErr: true, hideLoading: true })
}

// 查询走马灯
export const queryMarquee = () => {
  return post(
    '/api/phoenix/common/open/stationMsgContent/queryMarqueeContentFront',
    {},
    { hiddeErr: true, hideLoading: true }
  )
}

// 查询网站奖池列表
export const cmsPrizePool = (params: any) => {
  const data = {
    themeCode: 'ANIMEFRESH',
    categoryCode: 'HomeConfigTemplate',
    ...params
  }
  return post('/api/phoenix/cms/open/cmsPrizePool/list', data, { hiddeErr: true, hideLoading: true })
}

// 查询大客榜单
export const queryJackpotOrder = (params: any) => {
  return post('/api/v1/game/order/get_ranking_bet_record', params, { hideLoading: true })
}
// 查询网站最近注单记录
export const queryAllOrder = (params: any) => {
  return post('/api/v1/game/order/get_all_bet_record', params, { hideLoading: true })
}

// 爆奖注单排行榜
export const queryMyOrder = (params: any) => {
  return post('/api/v1/game/order/get_front_own_bet_record', params, { hideLoading: true })
}
// 查询网站amigo游戏派奖排行记录
export const gamePayoutRanking = () => {
  return post('/api/phoenix/game/open/front/gamePayoutRanking', {}, { hideLoading: true })
}
// 获取jackpotTie活动奖池信息
export const getJptPoolsInfo = (params: any) => {
  return post('/api/v1/game/jackpot/agin_info', params, { hideLoading: true })
}
// 获取jackpotTie活动历史记录
export const getJptAwardHistory = (params: any) => {
  return post('/api/v1/game/jackpot/agin/history', params, { hideLoading: true })
}
// 获取jackpotTie活动排行榜信息
export const getJptRankInfo = (params: any) => {
  return post('/api/v1/game/jackpot/agin/detail/user', params, { hideLoading: true })
}

/* islot jackpot查询 */
export const getJackpotListApi = () => {
  return post('/api/phoenix/game/open/front/islotJackpotList', {}, { hideLoading: true })
}
