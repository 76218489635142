import { useEffect, FC, useRef, useLayoutEffect, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { usePathname } from 'next/navigation'
import styles from './_common_css/gameSeachResult.module.scss'
import { Popover } from 'antd'
import { useDebounceFn, useClickAway } from 'ahooks'
import GameItem from './gameItem'
import { useGameStore } from '@/stores/game'
import { useGameData } from '../../app/[lng]/(game)/_components/useGameData'
import Nodata from '@/components/base/noData'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  visible: boolean
  searchGameName: string
  searchProviderList: any[]
  activeKey: any // -1   //多机台筛选标签 -1 不是多机台 0 全部 1 收藏 2 pachinko
  updateVisible: any
}

const GameSeachResult: FC<IProps> = (props) => {
  const { activeKey = -1 } = props
  const { t } = useTranslation()
  const pathName = usePathname()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { favoriteGameList, allGameLen } = useGameStore(
    useShallow((state) => ({
      favoriteGameList: state.favoriteGameList,
      allGameLen: state.allGameLen
    }))
  )
  const seach_box = useRef<any>(null)
  const seach_content = useRef(null)
  // const emits = defineEmits(["update:modelValue"])

  const {
    filterGameList,
    totalCount,
    useSearchType,
    setUseSearchType,
    setFilterGameList,
    setTotalCount,
    getGameData
  } = useGameData()

  const seachFn = () => {
    if (props.searchGameName === '' && props.searchProviderList.length === 0 && activeKey === -1) return
    delete useSearchType.gameIds
    delete useSearchType.gameTypes
    if (activeKey === 1) {
      const list = favoriteGameList
      if (list.length === 0) return
      const ids = list.map((e) => {
        return e.gameId
      })
      setUseSearchType({ gameIds: ids })
    } else if (activeKey > 1) {
      setUseSearchType({ gameTypes: [['pachinko', 'slot', 'live'][activeKey - 2]] })
    }
    setUseSearchType({ comboName: props.searchGameName, providerCodes: props.searchProviderList, pageNo: 1 })
    getGameData()
    // document.querySelector('.seach_content').scrollTo(0, 0)  // 待处理
  }

  const { run } = useDebounceFn(seachFn, { wait: 300 })

  const handleScroll = (e) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
      filterGameList.length < totalCount
    ) {
      useSearchType.pageNo = useSearchType.pageNo + 1
      getGameData()
    }
  }

  let closeTimeId
  let stopClickOutside
  const setClickOutsideEvent = () => {
    if (stopClickOutside) stopClickOutside()

    stopClickOutside = useClickAway(() => {
      if (props.visible) {
        if (closeTimeId) {
          clearTimeout(closeTimeId)
        }
        closeTimeId = setTimeout(() => {
          closeTimeId = null
          // 待观察
          props.updateVisible(false)
          // emits("update:modelValue", false)
        }, 0)
      }
    }, seach_content)
  }

  // 待观察，原项目放在onMounted内的nexttick
  useLayoutEffect(() => {
    if (activeKey !== -1) {
      const seachDom = document.querySelector('.seach_content')
      seachDom && seachDom.addEventListener('scroll', handleScroll, true)
    }
    if (activeKey === 0) {
      getGameData()
    }
  }, [])

  useEffect(() => {
    run()
  }, [props.searchGameName])

  useEffect(() => {
    // seachFn()  改成 run 待观察
    run()
  }, [activeKey])

  useEffect(() => {
    if (activeKey !== -1) return
    const seachDom = document.querySelector('.seach_content')
    if (props.visible) {
      if (props.searchProviderList.length > 0) {
        // seachFn() 改成 run 待观察
        run()
      }
      setTimeout(() => {
        setClickOutsideEvent()
        seachDom.scrollTo(0, 0)
        seachDom && seachDom.addEventListener('scroll', handleScroll, true)
      }, 300)
    } else {
      stopClickOutside && stopClickOutside()
      seachDom && seachDom.removeEventListener('scroll', handleScroll)
      setFilterGameList([])
      setTotalCount(0)
    }
  }, [props.visible])

  return (
    <>
      <section
        className={cn(
          styles['game_seach_box'],
          styles[isBigScreen ? 'pc' : 'h5'],
          styles[activeKey !== -1 && 'multimode_content']
        )}
        ref={seach_box}
      >
        {activeKey === -1 ? (
          <Popover
            trigger="hover"
            placement="bottomRight"
            // getPopupContainer={() => seach_box.current}  // 会渲染到父节点body, 但有shadow root报错 待处理
            overlayClassName={cn('seachPop', isBigScreen ? 'pc' : 'h5')}
            forceRender
            open={props.visible}
            content={
              <>
                {totalCount === 0 && props.searchGameName.length > 1 && (
                  <p
                    className={'seach_title_null'}
                    dangerouslySetInnerHTML={{ __html: t('home.seach.text7') }}
                  ></p>
                )}
                {(props.searchGameName.length > 0 || props.searchProviderList.length > 0) &&
                  totalCount > 0 && (
                    <p className={'seach_title'}>
                      <span>{t('home.seach.text5')}</span>
                      <span>{t('home.game.result', { target: totalCount, total: allGameLen })}</span>
                    </p>
                  )}
                {totalCount === 0 && props.searchGameName.length <= 10 && (
                  <p className={'seach_title_null'}>{t('home.seach.text6')}</p>
                )}
                <div ref={seach_content} className={cn('seach_content', 'scrollbar')}>
                  {filterGameList.map((item) => (
                    <GameItem
                      isSearchResult
                      key={item.gameId + item.gameType + item.platformCode}
                      gameData={item}
                    ></GameItem>
                  ))}
                </div>
              </>
            }
          />
        ) : (
          <div
            className={cn(
              pathName === '/account/multimode' && !isBigScreen ? 'hight' : undefined,
              'seach_content',
              'scrollbar'
            )}
          >
            {filterGameList.map((item) => (
              <GameItem
                isSearchResult
                key={item.gameId + item.gameType + item.platformCode}
                gameData={item}
              ></GameItem>
            ))}
            {filterGameList.length === 0 && <Nodata />}
          </div>
        )}
      </section>
    </>
  )
}

export default memo(GameSeachResult)
