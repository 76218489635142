// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { useState, useEffect } from 'react'
import { useSetState } from 'ahooks'
import { getGameList, gameFavorQuery, getAmigoInfo } from '@/api/game'
import { useAppStore } from '@/stores/app'
import { useGameStore } from '@/stores/game'
import { useShallow } from 'zustand/react/shallow'

export const useGameData = (gameType = '') => {
  const language = useAppStore((state) => state.language)
  const { kamikazeInfo, getKamikazeStore } = useGameStore(
    useShallow((state) => ({
      kamikazeInfo: state.kamikazeInfo,
      getKamikazeStore: state.getKamikazeStore
    }))
  )
  const [filterGameList, setFilterGameList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [useSearchType, setUseSearchType] = useSetState<any>({
    pageNo: 1,
    gameTypes: '',
    gameCategories: '',
    platformCode: '',
    orderByClause: ''
  })
  const [useGameType, setUseGameType] = useState('')
  useEffect(() => {
    setUseGameType(gameType)
  }, [])

  const getGameData = () => {
    const seachGameFn = () => {
      if (useGameType !== '') {
        setUseSearchType({ gameTypes: [useGameType] })
        //pachinko 特殊处理 tab标签 不走gameCategories 查询
        if (useGameType === 'pachinko') {
          if (!useSearchType.gameCategories || !useSearchType.gameCategories[0]) {
            setUseSearchType({ gameTypes: ['pachinko', 'pachislot'] })

            // delete useSearchType.platformCode //  待处理
          } else {
            setUseSearchType({ gameTypes: useSearchType.gameCategories })
            if (
              useSearchType.gameCategories[0] === 'KAMIKAZE' ||
              useSearchType.gameCategories[0] === 'IPACHIN'
            ) {
              setUseSearchType({ gameTypes: ['pachinko', 'pachislot'] })
              setUseSearchType({ platformCode: useSearchType.gameCategories[0] })
            } else {
              setUseSearchType({ platformCode: 'AMIGO' })
            }
          }
        }
      }
      const obj = {
        ...useSearchType.value,
        pageSize: 30,
        language: language,
        visibleType: 1
      }
      if (useGameType === 'pachinko') {
        obj.gameCategories = []
      }
      getGameList(obj).then(async (res: any) => {
        if (res.success && res.data) {
          if (useSearchType.pageNo === 1) {
            setFilterGameList(res.data.data)
          } else {
            const arr = [...filterGameList, ...res.data.data]
            setFilterGameList(arr)
          }
          if (useGameType === 'pachinko' && res.data.data.length > 0) {
            const len = res.data.data.length
            const ids = res.data.data
              .filter((e) => e.platformCode !== 'KAMIKAZE' && e.platformCode !== 'IPACHIN')
              .map((el) => el.gameId)
            if (ids.length > 0) {
              getAmingoDel(ids)
            }
            if (len !== ids.length && kamikazeInfo.length > 0) {
              setIpachinInfo()
            }
          }
          if (useSearchType.pageNo === 1) {
            setTotalCount(res.data.totalRow)
          }
        } else {
          setFilterGameList([])
          setTotalCount(0)
        }
      })
    }
    const setIpachinInfo = () => {
      if (kamikazeInfo.length > 0) {
        filterGameList.forEach((element) => {
          const property = kamikazeInfo.filter((tt: any) => tt.machineNumber === element.gameId)[0]
          if (property && property.gameCurrencyPrice) {
            const one = Math.floor(Number(property.gameCurrencyPrice) * 100) / 100
            property.baseRate = one * 3
            property.oneTurn = one
            property.onePiece = one
            property.ceiling = property.celling
            property.roundAmount = property.typeClassJpName
            property.type = property.typeClassJpName
            element.property = property
          }
        })
      }
    }
    //Amingo机台数据
    const getAmingoDel = (gameIdList: any[]) => {
      let i = 0
      getAmigoInfo({ gameIdList }).then((res: any) => {
        if (res.success && res.data) {
          filterGameList.forEach((element) => {
            const property = res.data[element.gameId]
            if (property) {
              element.property = property
              if (property.state !== 1) {
                i++
              }
            }
          })
          setTotalCount(totalCount - i)
          const filterGameTemp = [...filterGameList]
          filterGameTemp.filter(
            (e) => e.property?.state === 1 || e.platformCode === 'KAMIKAZE' || e.platformCode === 'IPACHIN'
          )
          setFilterGameList(filterGameTemp)
        }
      })
    }
    const getGameFavor = () => {
      gameFavorQuery({ pageSize: 999, language: language }).then((res: any) => {
        if (res.success && res.data && res.data.data.length > 0) {
          const gameIds = []
          res.data.data.forEach((el) => {
            gameIds.push(el.gameId)
          })
          getGameList({
            gameIds,
            pageSize: 999,
            language: language
          }).then(async (res: any) => {
            if (res.success && res.data) {
              setFilterGameList(res.data.data)
            }
          })
        }
      })
    }
    if (kamikazeInfo.length === 0) {
      getKamikazeStore(setIpachinInfo)
    }
    if (gameType === 'favorite') {
      getGameFavor()
    } else {
      seachGameFn()
    }
  }
  return {
    filterGameList,
    totalCount,
    useSearchType,
    useGameType,
    getGameData,
    setUseSearchType,
    setFilterGameList,
    setTotalCount
  }
}
