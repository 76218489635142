import { useMemo, FC, useCallback, memo } from 'react'
import { cn, formatGameImg } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { usePathname } from 'next/navigation'
import { useGameStore } from '@/stores/game'
// --- 待处理
import { openGame, favoriteGameFun } from '@/components/game/startGame'
import AnimationNum from '@/components/base/AnimationNum'
import { numThFilter, formatAmount } from '@/lib/filter'
import styles from './_common_css/gameItem.module.scss'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  gameData?: {
    gameName: string
    totalBetAmount: string
    lobbyCode: string
    gameType: string
    gameId: string
    platformCode: string
    gameProperties: any
    smallMediaLibrary: {
      fullWebpFilePath: string
      fullFilePath: string
    }
    rtp: any
    trialStatus: number
  }
  isSearchResult?: Boolean // false
  colorList?: any[]
  isSameHeight?: Boolean // false   部分页面需要高度一致
  gameIndex?: any
}

const GameItem: FC<IProps> = (props) => {
  const { isSearchResult = false, isSameHeight = false } = props
  const { t } = useTranslation()
  const { isBigScreen, language, commonConfig } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language,
      commonConfig: state.commonConfig
    }))
  )
  const { gameSearchDialogShow, favoriteGameList } = useGameStore(
    useShallow((state) => ({
      gameSearchDialogShow: state.gameSearchDialogShow,
      favoriteGameList: state.favoriteGameList
    }))
  )
  const isLogin = useUserStore((state) => state.isLogin)
  const pathName = usePathname()
  // 待处理
  // const { proxy } = getCurrentInstance()
  // const attrs = useAttrs()

  const isPachinko = useCallback((gameData) => {
    return (
      (gameData?.gameType === 'pachinko' || gameData?.gameType === 'pachislot') &&
      gameData?.lobbyCode === 'AMIGO' &&
      !isSameHeight
    )
  }, [])

  const backgroundColor = useMemo(() => {
    if (props.colorList && props.colorList.length > 1) {
      return `linear-gradient(
          to top,
         ${props.colorList[1]?.color} 0%,
          ${props.colorList[1]?.color} ${isPachinko(props.gameData) ? '40%' : '24%'},
          ${props.colorList[0]?.color},
          ${props.colorList[0]?.color}
        )`
    } else {
      return `linear-gradient(
          to top,
          rgba(91, 0, 33, 1) 0%,
          rgba(91, 0, 33, 1) ${isPachinko(props.gameData) ? '40%' : '24%'},
          rgba(91, 0, 33, 0),
          rgba(91, 0, 33, 0)
        )`
    }
  }, [])

  const gameBackgroundColor = useMemo(() => {
    if (props.colorList && props.colorList.length > 1) {
      return `linear-gradient(180deg, ${props.colorList[1]?.color} 0%, ${props.colorList[0]?.color} 100%)`
    } else {
      return `linear-gradient(180deg, rgba(238, 161, 128, 1) 0%, rgba(158, 88, 73, 1) 100%)`
    }
  }, [])

  // 收藏事件
  const favoriteFun = (item) => {
    favoriteGameFun(item)
  }

  const onGoToGame = (isTry, params) => {
    if (gameSearchDialogShow || pathName === '/account/multimode') {
      openGame(isTry, params)
    } else {
      openGame(isTry, params)
    }
  }

  return (
    <>
      <div
        className={cn(
          styles['game_item'],
          styles[`game-type-${props.gameData.gameType}`], // ---  待观察...
          styles[isBigScreen ? 'pc' : 'h5'],
          styles[!isPachinko(props.gameData) && 'no-pachinko'],
          styles[isSearchResult && 'search-result'] // ---  待观察...
        )}
        style={{
          background: gameBackgroundColor
        }}
        onClick={() => onGoToGame(0, props.gameData)}
      >
        {props.gameData && (
          <div
            className={'game-img'}
            // v-lazy: 待处理
            // background-image={() => formatGameImg(props.gameData)}
            style={{ backgroundImage: `url(${formatGameImg(JSON.stringify(props.gameData), commonConfig)}` }}
          ></div>
        )}
        {isLogin && (
          <div
            className={cn(
              !isPachinko(props.gameData) && 'no-pachinko',
              favoriteGameList.some(
                (e) => e.gameId === props.gameData.gameId && e.providerCode === props.gameData.platformCode
              ) && 'favorited',
              'fav-icon'
            )}
            onClick={(event) => {
              event.stopPropagation
              favoriteFun(props.gameData)
            }}
          ></div>
        )}
        {isPachinko(props.gameData) ? (
          <div className={'game-name-wrap'}>
            <p className={cn('game-name', 'text-multi-ellipsis-l2')}>{props.gameData.gameName}</p>
            <div className={'daily-bet'}>
              <span className={'label'}>{t('common.game.dailyBet')}</span>
              {/* <AnimationNum num={props.gameData.totalBetAmount || 0} className={'value'}> */}
              {/* --- 待处理 */}
              {/* <>{props.gameData.totalBetAmount}</> */}
              {/* <template v-slot="slotProps">${ props.gameData.totalBetAmount ? numThFilter(slotProps.value, 0) : "0" }</template> */}
              {/* </AnimationNum> */}
            </div>
            {props.gameData.gameProperties && (
              <div className={cn(isBigScreen ? 'pc' : 'h5', 'game-tags')}>
                <div className={cn(language, 'game-tags-row', 'row1')}>
                  <span>
                    {props.gameData.gameType === 'pachislot'
                      ? t('common.game.ceiling')
                      : t('common.game.probability')}
                  </span>
                  <span>
                    {props.gameData.gameProperties.gameProperty
                      ? props.gameData.gameProperties.gameProperty.ceiling
                      : '-'}
                  </span>
                </div>
                <div className={cn(language, 'game-tags-row', 'row2')}>
                  <span>
                    {props.gameData.gameType === 'pachislot' ? t('common.game.token') : t('common.game.ball')}
                  </span>
                  <span>
                    $
                    {props.gameData.gameType === 'pachislot'
                      ? formatAmount(props.gameData.gameProperties.gameProperty.onePiece)
                      : formatAmount(props.gameData.gameProperties.gameProperty.oneBead, 3)}
                  </span>
                </div>
                <div className={cn(language, 'game-tags-row', 'row3')}>
                  <span>
                    {props.gameData.gameType === 'pachislot' ? t('common.game.type') : t('common.game.roll')}
                  </span>
                  <span>
                    {props.gameData.gameType === 'pachislot'
                      ? props.gameData.gameProperties.gameProperty.type
                      : formatAmount(props.gameData.gameProperties.gameProperty.oneTurn, 3)}
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={cn('game-name-wrap', 'no-pachinko')}>
            <p className={cn('game-name', 'text-multi-ellipsis-l2')}>{props.gameData.gameName}</p>
            <div className={'game-tags'}>
              <span>
                <span className={'label'}>RTP</span>
                <span className={'value'}>{(props.gameData.rtp * 100).toFixed(2)}%</span>
              </span>
            </div>
          </div>
        )}
        {isBigScreen && !isLogin && (
          <div className={'game_overlay'}>
            <div>
              {!isLogin && props.gameData.trialStatus === 1 && (
                <a onClick={() => onGoToGame(1, props.gameData)}>
                  {t('common.game.fun')}
                  <img className={'arrow'} src="/static/images/theme-red/arrow-right-white.svg" alt="" />
                </a>
              )}

              <a onClick={() => onGoToGame(0, props.gameData)}>
                {t('common.game.real')}
                <img className={'arrow'} src="/static/images/theme-red/arrow-right-white.svg" alt="" />
              </a>
            </div>
          </div>
        )}
        <div
          style={{
            background: backgroundColor
          }}
          className={'after'}
        ></div>
        {props.gameData.smallMediaLibrary && (
          <img
            src={
              props.gameData.smallMediaLibrary?.fullWebpFilePath ||
              props.gameData.smallMediaLibrary?.fullFilePath
            }
            className={'top_icon'}
          />
        )}
      </div>
    </>
  )
}

export default memo(GameItem)
