import { useState, useEffect, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { cmsGameLabels } from '@/api/home'
import { Input, Skeleton } from 'antd'
import { useRouter, usePathname } from 'next/navigation'
import styles from './_common_css/gameProvideSearch.module.scss'
/* com */
//  待处理
import SlotsGameProvider from './slotsGameProvider'
import GameSeachResult from './gameSeachResult'
import GameSeachPageResult from './gameSeachPageResult'
import CasinoBox from './casino'
import JackPot from './jackPot'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  activeKey?: number // -1
  searchPage?: boolean // false
  inputText?: string // ""
  className?: string
}

const GameProvideSearch: FC<IProps> = (props) => {
  const { searchPage = false, activeKey = -1, inputText = '' } = props
  const { t } = useTranslation()
  const userInfo = useUserStore((state) => state.userInfo)
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const [isShowSearchResult, setIsShowSearchResult] = useState(false)
  const [searchProviderList, setSearchProviderList] = useState([])
  const [searchGameName, setSearchGameName] = useState('')
  const [frontDataList, setFrontDataList] = useState([])
  const [spinning, setSpinning] = useState(true)
  // const fastSearch = ["CR", "バカラ", "甲", "海", "神", "北斗", "Fish", "Joker", "Fruit", "Book", "Big", "100", "Sweet"]
  const fastSearch = ['CR', 'バカラ', '海', '神', '北斗', 'Book', 'Big', '100', 'Sweet']
  if (language !== 'ja-JP') {
    fastSearch[1] = 'Baccarat'
  }
  const showProvide = () => {
    setIsShowSearchResult(false)
  }

  const handleFinish = (val) => {
    setSearchProviderList(val.checkedList)
    setIsShowSearchResult(val.checkedList.length > 0)
  }

  const getGameLabels = () => {
    return cmsGameLabels({ moduleCode: 'labelClassification', username: userInfo.loginName })
      .then((res: any) => {
        if (res.code === 200 && res.success && res.data.length > 0) {
          res.data.forEach((el) => {
            el.detailsList.forEach((ll) => {
              /**
               * 因ll.providerCode = ll.lobbyCode中providerCode与以前进游戏有关，不可删除；
               * providerName: 搜索页数据来源/element/material/game_page，无providerCode字段；
               * providerName: 首页Casino模块中数据来源/cms/open/gameLabels/gameLabelsList，需使用providerCode字段；
               * 进游戏仅需使用gameId与providerCode，不受字段影响，故赋值在providerName中进行使用。
               */
              ll.providerName = ll.providerCode
              ll.providerCode = ll.lobbyCode
              ll.platformCode = ll.lobbyCode
            })
          })
          setFrontDataList(res.data || [])
        }
      })
      .finally(() => {
        setSpinning(false)
      })
  }

  useEffect(() => {
    searchPage && getGameLabels()
  }, [])
  // --- 待处理 原项目 无依赖

  useEffect(() => {
    // --- 待处理 && _this.$route.query.q
    if (rawPath === '/search' && isBigScreen) {
      // setSearchGameName(_this.$route.query.q) --- 待处理
      setIsShowSearchResult(true)
    }
  }, [router])

  useEffect(() => {
    if (searchGameName !== undefined && isBigScreen) {
      let searchParams = new URLSearchParams(window.location.search)
      searchParams.set('q', searchGameName)

      if (searchGameName === '') {
        window.history.replaceState({}, '', `${window.location.pathname.indexOf('/ja') > -1 ? '/ja/' : ''}`)
      } else {
        let url = window.location.pathname.indexOf('/ja') > -1 ? '/ja/search' : '/search'
        window.history.replaceState({}, '', `${url}?${searchParams.toString()}`)
      }
    }

    setIsShowSearchResult(!!(searchProviderList.length > 0 || searchGameName))
  }, [searchGameName])

  const resetInput = () => {
    setSearchGameName('')
  }

  // --- 待处理
  // defineExpose({ resetInput })

  return (
    <>
      <div
        className={cn(
          styles['game-provide-search'],
          styles[isBigScreen ? 'pc' : 'h5'],
          styles[activeKey !== -1 && 'multimode'],
          props.className
        )}
      >
        {/* 待处理 */}
        {activeKey !== 1 && (
          <div className={'input-warp'}>
            <Input
              value={searchGameName}
              placeholder={inputText || t('home.game.input')}
              prefix={
                <SlotsGameProvider finish={handleFinish} className={'solitics_SelectGameProviderBtn'} />
              }
              suffix={<img className={'search-icon'} src="/static/images/game/search-icon.svg" />}
            ></Input>
          </div>
        )}

        {searchPage && (
          <div className={'fast-search'}>
            {fastSearch.map((item) => (
              <span key={item} onClick={() => setSearchGameName(item)}>
                {item}
              </span>
            ))}
          </div>
        )}

        {searchPage && (
          <Skeleton loading={spinning} title={false} paragraph={{ rows: 8 }} className={'search-def'}>
            {!searchGameName && searchProviderList.length === 0 && (
              <div className={'search-def'}>
                {frontDataList.length > 0 && (
                  <CasinoBox data={frontDataList} title={t('home.betList.casino')} />
                )}
                <JackPot />
              </div>
            )}
          </Skeleton>
        )}
        {searchPage ? (
          <>
            {(searchGameName || searchProviderList.length > 0) && (
              <GameSeachPageResult searchGameName={searchGameName} searchProviderList={searchProviderList} />
            )}
          </>
        ) : (
          <GameSeachResult
            visible={isShowSearchResult}
            searchGameName={searchGameName}
            searchProviderList={searchProviderList}
            activeKey={activeKey}
            updateVisible={setIsShowSearchResult}
          ></GameSeachResult>
        )}
      </div>
    </>
  )
}

export default memo(GameProvideSearch)
