import { FC } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import styles from './_common_css/noData.module.scss'

type IProps = {
  style?: any
}

const NoData: FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={cn(props.style, styles['no-result'])}>
        <div className={styles['content']}>
          <div className={styles['icon']}></div>
          <p className={styles['text']}>{t('common.game.nodata')}</p>
        </div>
      </div>
    </>
  )
}

export default NoData
